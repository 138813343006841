footer {
    /* position: relative; */
    /* width: 100%; */
    height: auto;
    padding: 0px 10px;
    /* background:linear-gradient(to right, #00093c,#2d0b00); */
    /* background:linear-gradient(to right,#04188c,#68376a); */
    z-index: 80; /* Added z-index to ensure footer is below the navbar */
    /* margin-left: -9%; */
}

footer .container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
}

footer .container .gggg{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
    margin-left: 50px;
    
}

@media (768px< width < 1440px) {
    footer .container .gggg{
     
        grid-gap: 5px;
        margin-left: 5px;
        
    }
}
footer .container .sec h2 {
    position: relative;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: 'Neusharp', sans-serif;
}
footer .container .sec img{
    margin-top: 15px;
}
footer .container .sec p {
    margin-top: 10px;
    color: #000;
    font-family: "Playfair Display", system-ui;
    font-size: 18px;
    font-family: Georgia, serif;
}

footer .container .sci {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 50px);
    justify-content: center;
}

footer .container .sci li {
    list-style: none;
}

footer .container .sci li a {
    display: inline-block;
    width: 36px;
    height: 36px;
    background: white;
    display: grid;
    align-content: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 30px;
}

footer .container .sci li a i {
    
    font-size: 25px;
}

footer .container .quicklinks h2 {
    margin-bottom: 15px;
    font-family: 'Neusharp', sans-serif;
    color: #121C87;
}

footer .container .quicklinks ul li {
    list-style: none;
}

footer .container .quicklinks ul li a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
    display: inline-block;
    font-size: medium;
}

footer .container .contact .info {
    list-style: none;
    position: relative;
    left: -20px;
}

footer .container .contact h2 {
    margin-bottom: 15px;
    font-family: 'Neusharp', sans-serif;
    color:#121C87;
}

footer .container .contact .info li {
    display: grid;
    grid-template-columns: 30px 1fr;
/*     margin-bottom: 16px; */
}

footer .container .contact .info li span {
    color:blue;
    font-size: 20px;
}

footer .container .contact .info li a {
    color: #000;
    text-decoration: none;
}

.copyrighttext {
    /* background:linear-gradient(to right, #00093c,#2d0b00); */
    background-color: #111111;
    /* background-color:transparent; */
    /* background: linear-gradient(to right, #bc005b, #4528a5); */
    padding: 20px 100px 20px;
    text-align: center;
    color: #fff;
    /* border: 1px solid rgba(146, 143, 143, 0.268); */
    border-top:1px solid rgba(146, 143, 143, 0.268); ;
}
.copyrighttext a{
    text-decoration: none;
    color: #fff;
}
.copyrighttext p{
    margin-bottom: 0px;
}
.sec span{
    color: #ff004f;
    font-size: 30px;
}


.underline{
    width: 40%;
    height: 5px;
    background: #1011e7;
    border-radius: 3px;
    position: relative;
    top: 5px;
    left: 5px;
    overflow: hidden;
}
.underline span{
width: 15px;
height: 100%;
background: #fff; 
position: absolute;
top: 0px;
left: 10px;
animation: moving 2s linear infinite;

}


.lp{
font-size: 60px;
margin-right: 10px;
margin-top: 20px;
}
@keyframes moving{
    0%{
        left: -20px;
    }
    100%{
        left: 100%;
    }
}

@media (max-width: 991px) {
    footer {
        padding: 40px;
        /* margin-top: 649%; */
        /* margin-left: 5px; */
    }
    footer .container {
        /* width: 100%; */
        display: grid;
        /* grid-template-columns: repeat(2, 1fr); */
        grid-gap: 20px;
    }
    .copyrighttext {
        padding: 20px 40px 30px;
    }
    
}

@media (max-width: 768px) {
    footer .container {
        /* width: 100%; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }
   
  
   
}
@media (max-width: 450px) {
    footer .container {
        /* width: 100%; */
       
    }
    .sec p{
        width: 100%;
    }

    footer .container .gggg{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;
        margin-left: 0px;
    }
}


footer .sec{
   
    text-align: center;
    margin-left: -20px;
}

footer .sec img{
    width: 80px;
    height: 80px;
}

@media (768px < width <=1440px) {
    footer .container {
     
        grid-gap: 0px;
    }

    .lp{
        font-size: 25px;
        margin-right: 10px;
        margin-top: 20px;
        }
}
