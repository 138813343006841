/* src/Styles/log.css */

.login-container {
  display: flex;
  height: 100%;
    background-color: white;
}

.login-left {
  flex: 1;
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #D7B067 0%, #FFFFFF 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.rightLogo img {
  height: 150px;
  width: 150px;
}

.rightLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Added margin for spacing */
}

.intro {
  background-image: url('../img/WhatsApp Image 2024-09-28 at 5.54.20 PM.jpeg');
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 0px 50px 50px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.au{
  height: 50%;
}
.intro p {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  margin-top: 30px;
}




.office-image {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.contact-overlay .contact-info h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #000;
}

.contact-info {
  background: rgba(255, 255, 255, 0.721);
  border-radius: 0.5rem;
  padding: 1rem;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #333;
}

.contact-item i {
  margin-right: 0.5rem;
}

.login-right {
  flex: 1;
  padding: 1.5rem;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
}

.login-right h2 {
  color: #000;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 30px;
}

.login-right h2 span {
  color: #FE5005;
  font-size: 40px; 
}

form {
  display: flex;
  flex-direction: column;
}

.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.remember-me label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.remember-me label input {
  margin-right: 5px;
}

button {
  color: #fff;
  background-color: #fc5b15ec;
  padding: 0.75rem;
  border: none;
  border-radius: 9999px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  margin-right: 5px;
}

button:hover {
  background-color: #e04e14;
}

.forgot-password {
  text-align: center;
}

.forgot-password a {
  text-decoration: none;
  font-size: 16px;
  color: #FFD8AF;
  transition: color 0.3s;
}

.forgot-password a:hover {
  text-decoration: underline;
}

@media (max-width:768px) {
  .login-container {
    flex-direction: column-reverse;
    height: auto; /* Adjust height for smaller screens */
  }
  
  .login-left {
    flex: none;
  }

  .intro p {
    font-size: 35px;
  }
}

.but {
  display: flex;
  justify-content: space-evenly;
}

.but button {
  width: 133px;
}
