/* foot.css */

.footer {
    background-color: #333333;
    color: white;
    padding: 2rem 1rem;
}

.footer-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
}
.footer-connect h2{
color: #e9b451;
}
.footer-links h2{
    color: #e9b451;
}
.footer-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}

.footer-logo {
    text-align: center;
}

.logo-link {
    display: inline-block;
    margin-bottom: 1rem;
}

.logo-image {
    height: 6rem;
    width: auto;

}

.gstin {
    font-size: 1.125rem;
}

.footer h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
}

.social-icon {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    transition: color 0.3s, text-decoration 0.3s;
}

.social-icon:hover {
/* Example hover color */
    text-decoration: underline;
}

.contact-info, .whatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.email, .whatsapp {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
}

.email:hover, .whatsapp:hover {
    color: #FFD700; /* Example hover color */
}

.footer-links {
    text-align: center;
}

.footer-links ul {
    list-style-type: none;
    padding: 0;
}

.footer-links li {
    margin-bottom: 0.5rem;
}

.footer-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s, text-decoration 0.3s;
}

.footer-links a:hover {
    color: #FFD700; /* Example hover color */
    text-decoration: underline;
}

.footer-divider {
    border-color: rgba(255, 255, 255, 0.2);
    margin: 2rem 0;
}

.footer-bottom {
    text-align: center;
    font-size: 1.125rem;
}
.contact-infoo{
    margin-bottom: 15px;
}
.contact-infoo a{
text-decoration: none;
font-size: 18px;
color: #Fff;

}
.location a{
    text-decoration: none;
    font-size: 18px;
    color: #Fff;
    }
/* Responsive Layout Adjustments */
@media (min-width: 576px) {
    .footer-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .footer h2 {
        text-align: left;
    }

    .social-icons {
        justify-content: flex-start;
    }

    .contact-info, .whatsapp {
        justify-content: flex-start;
    }

    .footer-links {
        text-align: left;
    }
}

@media (min-width: 768px) {
    .footer-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .footer h2 {
        text-align: left;
    }

    .social-icons {
        justify-content: flex-start;
    }

    .contact-info, .whatsapp {
        justify-content: flex-start;
    }

    .footer-links {
        text-align: left;
    }
}

@media (min-width: 1024px) {
    .footer-container {
        padding: 2rem;
    }

    .footer-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .footer h2 {
        font-size: 1.75rem;
    }

    .social-icon {
        font-size: 1.75rem;
    }

    .footer-bottom {
        font-size: 1.25rem;
    }
}
