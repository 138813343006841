/* Container styles */
.delete-account-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: transparent;
    padding: 10px; /* Add padding for smaller screens */
  }
  
  /* Box styles */
  .delete-account-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%; /* Allow the box to shrink to fit smaller screens */
 
  }
  
  /* Heading styles */
  .delete-account-box h2 {
    font-size: 24px;
    color: #d9534f;
    margin-bottom: 15px;
  }
  
  /* Paragraph styles */
  .delete-account-box p {
    font-size: 16px;
    color: #5a5a5a;
    margin-bottom: 20px;
  }
  
  /* Button group styles */
  .button-group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  /* Cancel button styles */
  .cancel-btn {
    background-color: #e0e0e0;
    color: #333;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .cancel-btn:hover {
    background-color: #d6d6d6;
  }
  
  /* Delete button styles */
  .delete-btn {
    background-color: #d9534f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .delete-btn:hover {
    background-color: #c9302c;
  }
  
  .delete-btn:disabled {
    background-color: #f5a5a3;
    cursor: not-allowed;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    /* Adjust box size for tablets and smaller screens */
    .delete-account-box {
      max-width: 90%; /* Reduce the width for tablets */
      padding: 15px; /* Reduce padding */
    }
  
    /* Adjust font sizes for smaller screens */
    .delete-account-box h2 {
      font-size: 20px;
    }
  
    .delete-account-box p {
      font-size: 14px;
    }
  
    .cancel-btn, .delete-btn {
      font-size: 12px;
      padding: 8px 15px; /* Smaller buttons */
    }
  }
  
  @media (max-width: 480px) {
    /* Adjust box size for mobile screens */
    .delete-account-box {
      max-width: 95%; /* Maximize the use of screen space */
    }
  
    /* Adjust font sizes for mobile screens */
    .delete-account-box h2 {
      font-size: 18px;
    }
  
    .delete-account-box p {
      font-size: 12px;
    }
  
    .cancel-btn, .delete-btn {
      font-size: 10px;
      padding: 6px 10px; /* Smaller buttons */
    }
  }
  