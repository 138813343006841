.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9;
  }
  
  .error-container {
    text-align: center;
    max-width: 600px;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .error-container h1 {
    font-size: 2.5rem;
    color: red;
  }
  
  p {
    /* color: #555;
    margin: 1rem 0; */
  }
  
  .error-image {
    margin-top: 1.5rem;
    width: 100%;
    max-width: 300px;
  }
  
  .message-box {
    margin-top: 2rem;
  }
  
  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
  }
  
  .buttons .button {
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .buttons .button:hover {
    background-color: #0056b3;
  }
  